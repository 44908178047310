import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export default function HomeFunction() {

  const router = useRouter();

  useEffect(
    () => {
      router.push('/children');
    }, []
  );

  return (
    <>
      <Head>
        <title>MonkeyBox</title>
      </Head>
    </>
  );
}

